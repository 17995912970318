@import './colors.scss';

.flex-field-group {
  display: flex;
  gap: 1rem;
  formly-field {
    flex: 1;
  }

  @media (max-width: 57.5rem) {
    flex-wrap: wrap;
  }
}

.mat-mdc-form-field-type-checkbox {
  .mat-mdc-text-field-wrapper {
    padding: 0 !important;
    background-color: $light-background-color !important;
    .mat-mdc-form-field-infix {
      padding: 0;
      background: $light-background-color;
    }
  }
}
