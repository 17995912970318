// fonts
@import url('https://fonts.googleapis.com/css2?family=Pattaya&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Pattaya&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

.display-font {
  font-family: 'Pattaya', sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

body,
.body-font {
  font-family: 'Merriweather', serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 1.8rem !important;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}
