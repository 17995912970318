html {
  font-size: 62.5%;
  scroll-padding-top: 9.5rem;
}

body {
  margin: 0;
  font-size: 1.6rem;
}

* {
  box-sizing: border-box;
}

#root {
  mat-icon {
    font-size: 1.8rem;
    width: 1.8rem;
    height: 1.8rem;
  }
}
