// page setup
tags-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#footer {
  margin-top: auto;
}

// components
.page-section {
  display: flex;
  justify-content: center;
  padding: 5rem;

  .section-content {
    width: 100%;
    max-width: 160rem; // ~1600px
  }
}

.card {
  padding: 1rem 2rem;
  border-radius: 3px;
  box-shadow: 0 3px 5px rgba($color: #000000, $alpha: 0.25);
}
