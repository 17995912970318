// using ID first will scope these styles to override 3rd party styles
#root {
  /* margin */
  .m-0 {
    margin: 0;
  }
  .m-1 {
    margin: 1rem;
  }
  .m-2 {
    margin: 2rem;
  }

  /* margin horizontal */
  .mh-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .mh-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mh-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  /* margin vertical */
  .mv-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .mv-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .mv-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  /* margin left */
  .ml-0 {
    margin-left: 0;
  }
  .ml-1 {
    margin-left: 1rem;
  }
  .ml-2 {
    margin-left: 2rem;
  }

  /* margin right */
  .mr-0 {
    margin-right: 0;
  }
  .mr-1 {
    margin-right: 1rem;
  }
  .mr-2 {
    margin-right: 2rem;
  }

  /* padding */
  .p-0 {
    padding: 0;
  }
  .p-1 {
    padding: 1rem;
  }
  .p-2 {
    padding: 2rem;
  }

  /* padding horizontal */
  .ph-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .ph-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .ph-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  /* padding vertical */
  .pv-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .pv-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .pv-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  /* padding left */
  .pl-0 {
    padding-left: 0;
  }
  .pl-1 {
    padding-left: 1rem;
  }
  .pl-2 {
    padding-left: 2rem;
  }

  /* padding right */
  .pr-0 {
    padding-right: 0;
  }
  .pr-1 {
    padding-right: 1rem;
  }
  .pr-2 {
    padding-right: 2rem;
  }
}
