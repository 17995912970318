$primary-color: #079da0;
$text-on-primary-color: #ffffff;
$secondary-color: #19305b;
$text-on-secondary-color: #ffffff;
$light-background-color: #ffffff;
$light-accent-background-color: #f3f3f3;
$text-on-light-background-color: #000000;
$dark-background-color: #000000;
$text-on-dark-background-color: #ffffff;
$dark-accent-background-color: #292929;

.accent-bg {
  background-color: $light-accent-background-color;
  color: $text-on-light-background-color;
}
